<template>
  <div class="contact">
    <h3>我们将尽快与您联系</h3>
    <el-form
      ref="formRef"
      class="contact-form"
      :model="formState"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item label="姓名" prop="userName" class="form-row-item">
        <el-input
          v-model="formState.userName"
          placeholder="请输入姓名"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone" class="form-row-item">
        <el-input
          v-model="formState.phone"
          placeholder="请输入手机号"
          autocomplete="off"
          maxlength="11"
        />
      </el-form-item>
      <el-form-item label="邮箱" prop="email" class="form-row-item">
        <el-input
          v-model="formState.email"
          placeholder="请输入邮箱号"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="公司名" prop="company" class="form-row-item">
        <el-input
          v-model="formState.company"
          placeholder="请输入公司名"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="补充说明" class="form-row-item" prop="other">
        <el-input
          v-model="formState.other"
          placeholder="请输入补充说明"
          autocomplete="off"
          maxlength="200"
        />
      </el-form-item>
      <el-form-item label="" class="form-row-item" prop="agreePrivicy">
        <el-checkbox v-model="formState.agreePrivicy"></el-checkbox>
        <span class="agree">我同意</span>
        <span class="privicy" @click="goToPrivacyPage">隐私政策</span>
      </el-form-item>
      <el-button
        type="primary"
        class="submit-button"
        :loading="loading"
        @click="onSubmit"
      >
        立即提交
      </el-button>
    </el-form>
  </div>
  <div class="place">
    <div class="place-main">
      <h3>公司地址</h3>
      <div class="content">
        <div class="item">
          <img :src="sh" alt="" />
          <div class="info">
            <h4>UBiX 上海</h4>
            <p>上海市黄浦区淮海中路222号力宝广场3012室</p>
            <p>021-31755506</p>
          </div>
        </div>
        <div class="item">
          <img :src="bj" alt="" />
          <div class="info">
            <h4>UBiX 北京</h4>
            <p>北京市东城区隆福寺街95号隆福文创园1号楼6层</p>
            <p>010-21737318</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="follow">
    <div class="follow-main">
      <div class="content">
        <img :src="qrcode" alt="" />
        <div class="info">
          <h4>关注我们<br />获取更多最新信息</h4>
          <p>如有意见和建议，欢迎发送至<br />marketing@ubixai.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { apiContact } from '@/service/api/contact'
import { ElMessage } from 'element-plus'
import { IContactApiParams } from '@/service/api/contact/type'
import bj from '@/assets/contact/bj.png'
import sh from '@/assets/contact/sh.png'
import qrcode from '@/assets/contact/qrcode.png'
import { useRoute } from 'vue-router'
import router from '@/router'

const route = useRoute()

const formRef = ref<FormInstance>()
const formState = ref<IContactApiParams>({
  userName: '',
  phone: '',
  email: '',
  company: '',
  cooperationType: undefined,
  other: '',
  requestInfo: '',
  agreePrivicy: false,
})
const loading = ref(false)
const validatePhone = (rule: any, value: any, callback: any) => {
  const reg = /^1[3456789]\d{9}$/
  if (value === '') {
    callback(new Error('请输入手机号！'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号！'))
  } else {
    callback()
  }
}

const validateEmail = (rule: any, value: any, callback: any) => {
  const reg =
    /^([0-9A-Za-z]+[0-9A-Za-z\-_.]+)@([a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]{1,}(\.[a-zA-Z0-9-_]{1,})?)$/g
  if (value === '') {
    callback(new Error('请输入邮箱！'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确格式的邮箱！'))
  } else {
    callback()
  }
}

const validatePrivicy = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('请勾选隐私政策'))
  } else {
    callback()
  }
}

const rules = reactive<FormRules>({
  userName: [
    {
      required: true,
      message: '请输入姓名',
      pattern: '[^ \x22]+',
    },
  ],
  phone: [{ required: true, validator: validatePhone }],
  email: [{ required: true, validator: validateEmail }],
  company: [
    {
      required: true,
      message: '请输入公司名',
      pattern: '[^\x22]+',
      trigger: 'blur',
    },
  ],
  agreePrivicy: [{ required: true, validator: validatePrivicy }],
})
const onSubmit = async () => {
  if (!formRef.value) {
    return
  }
  await formRef.value.validate((valid, fields) => {
    if (valid) {
      const host = window.location.host
      formState.value.requestInfo = JSON.stringify({ host })
      if (route.query.source && Number.isInteger(+route.query.source)) {
        formState.value.cooperationType = +route.query.source
      } else {
        delete formState.value.cooperationType
      }
      onSubmitAction(formState.value)
    }
  })
}

const onSubmitAction = async (params: IContactApiParams) => {
  try {
    loading.value = true
    const res = await apiContact(params)
    if (res.code === 200) {
      ElMessage({
        message: '提交成功',
        type: 'success',
      })
      formRef.value?.resetFields()
    } else {
      ElMessage({
        message: '提交失败，请重试。',
        type: 'error',
      })
    }
  } catch (e) {
    ElMessage({
      message: '提交失败，请重试。',
      type: 'error',
    })
  } finally {
    loading.value = false
  }
}

const goToPrivacyPage = () => {
  // router.push('/privacy')
  const host = window.origin
  window.open(`${host}/ubix_privacy.html`)
}
</script>

<style lang="scss" scoped>
$colorFocus: rgba(42, 100, 197, 1);

h3 {
  height: 126px;
  line-height: 126px;
  font-size: $titleFont4;
  font-weight: $mediumWeight;
  color: $colorBlack1;
  text-align: center;
}
.contact {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0 120px;

  .contact-form {
    width: 650px;
    margin: 24px auto 0;
    background-color: $colorWhite1;
    padding: 40px 80px;
    box-shadow: 0px 0px 100px 0px rgba(103, 123, 147, 0.25);
    border-radius: 12px;
    .form-row-item {
      width: 436px;
      display: block;
      margin-bottom: 24px;
      :deep(.el-form-item__label) {
        color: $colorBlack1;
        height: $font6;
        line-height: $font6;
        margin-bottom: 8px;
      }
      :deep(.el-form-item__content) {
        .el-input {
          height: 40px;
          width: 490px;
        }
        .el-input__wrapper {
          padding: 0;
          box-shadow: 0 0 0 1px rgba(220, 223, 230, 1) inset;
        }
        .el-input__wrapper.is-focus {
          box-shadow: 0 0 0 1px $colorFocus inset;
        }
        .el-input__inner {
          height: 40px;
          padding-left: 16px;
          width: 490px;
          color: $colorBlack3;
        }
        .el-form-item__error {
          margin: 4px;
        }

        .el-checkbox {
          margin-right: 8px;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: $colorFocus;
          border-color: $colorFocus;
        }

        .el-checkbox__inner:hover {
          border-color: $colorFocus;
        }
      }

      .agree {
        color: $colorBlack3;
      }

      .privicy {
        color: $colorFocus;
        margin-left: 2px;
        cursor: pointer;
      }
    }
    .submit-button {
      width: 490px;
      height: 40px;
      background: $colorBgDark;
      border-radius: 4px;
      color: $colorWhite1;
      border: 0;
      &:hover {
        background: #666666;
      }
    }
  }
}
.place {
  width: 100%;
  background-color: $colorWhite1;
  padding: 0 0 60px;

  .place-main {
    @include width-1440;
  }

  .content {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .item {
      width: 553px;

      img {
        display: block;
        width: 553px;
        margin: 0;
        height: 369px;
        margin-bottom: 24px;
      }

      h4 {
        height: 39px;
        font-size: 28px;
        font-weight: $mediumWeight;
        color: $colorBlack1;
        line-height: 39px;
        margin-bottom: 8px;
      }

      p {
        height: 36px;
        line-height: 36px;
        font-size: $font5;
        color: $colorBlack2;
      }
    }
  }
}

.follow {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0;

  .follow-main {
    @include width-1440;

    .content {
      display: flex;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid #d9d9d9;
      height: 284px;
      padding: 60px;
      text-align: center;
    }

    img {
      width: 164px;
      height: 164px;
      margin-right: 30px;
    }
    h4 {
      font-size: 32px;
      font-weight: $mediumWeight;
      color: $colorBlack1;
      line-height: 48px;
      margin-bottom: 16px;
    }
    p {
      font-size: $font4;
      color: $colorBlack2;
      line-height: 24px;
    }
  }
}
</style>
