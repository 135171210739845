import { post } from '@/service/http'
import { IContactApiParams, IPage } from './type'

const apiContact = (params: IContactApiParams) => {
  return post(`/customer/save`, params)
}

const apiGetNews = (params: IPage) => {
  return post('/news/queryByCond', params)
}

const apiGetJobs = (params: IPage) => {
  return post('/invite/queryByCond', params)
}

export { apiContact, apiGetNews, apiGetJobs }
