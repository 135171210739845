<template>
  <div class="waterfall-width-column">
    <div
      v-for="item in imgList"
      :key="item.key"
      class="image-box"
      :style="{ marginBottom: item.hasBottom ? 14 + 'px' : 0 }"
    >
      <div class="img-desc">{{ item.title }}</div>
      <img :src="item.img" alt="" />
      <div class="img-mask"></div>
    </div>
    <div class="request-data">
    </div>
  </div>
</template>

<script lang="ts" setup>
import smartHome from '@/assets/ssp/scene-smartHome.png'
import videoLive from '@/assets/ssp/scene-videoLive.png'
import localLife from '@/assets/ssp/scene-localLife.png'
import news from '@/assets/ssp/scene-news.png'
import novelReading from '@/assets/ssp/scene-novelReading.png'
import campusStudy from '@/assets/ssp/scene-campusStudy.png'
import game from '@/assets/ssp/scene-game.png'
import toolBeautify from '@/assets/ssp/scene-toolBeautify.png'

const imgList = [
  {
    key: 1,
    img: smartHome,
    hasBottom: true,
    title: '智能家居',
  },
  {
    key: 2,
    img: novelReading,
    title: '小说阅读',
  },
  {
    key: 3,
    img: videoLive,
    hasBottom: true,
    title: '视频直播',
  },
  {
    key: 4,
    img: campusStudy,
    title: '校园学习',
  },
  {
    key: 5,
    img: localLife,
    hasBottom: true,
    title: '本地生活',
  },
  {
    key: 6,
    img: game,
    title: '游戏娱乐',
  },
  {
    key: 7,
    img: news,
    hasBottom: true,
    title: '新闻资讯',
  },
  {
    key: 8,
    img: toolBeautify,
    title: '工具美化',
  },
]
</script>

<style lang="scss" scoped>
.waterfall-width-column {
  position: relative;
  column-count: 4;
  column-gap: 14px;
  .image-box {
    position: relative;
    .img-desc {
      position: absolute;
      height: 34px;
      line-height: 34px;
      left: 36.28%;
      bottom: 8.79%;
      font-size: 24px;
      font-weight: 500;
      color: #fff;
      z-index: 5;
    }
    img {
      display: block;
      width: 100%;
    }
    .img-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .request-data {
    padding: 16px;
    position: absolute;
    width: 12.018%;
    height: 21.785%;
    left: 43.958%;
    top: 39.107%;
    background: $colorWhite1;
    background-image: url(@/assets/ssp/requestData.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.25);
  }
}
</style>
