<template>
  <section class="bd">
    <div class="platform">
      <p class="name">天权大数据中台</p>
      <p class="desc">数据驱动公司业务发展</p>
      <p class="effect">
        以数据为核心，通过数据产品工具为媒介，打通各个产品线，建立数据闭环思维，将运营，
      </p>
      <p class="effect">
        产品，销售，商务等纳入数据闭环体系中，通过数据加工、调整决策、及时行动、数据反
      </p>
      <p class="effect">馈，从而不断循环和迭代，使广告主或开发者的效益最大化</p>
    </div>
    <div class="business-wrapper">
      <div class="product-bg">
        <img
          v-if="isMobile()"
          :src="bdVideoImg"
          style="width: 100%; margin: 0 auto"
        />
        <video v-else id="myVideo" loop autoplay muted :style="{}">
          <source :src="videoSrc" type="video/mp4" />
        </video>
      </div>
      <div class="business-main">
        <div class="desc top-desc">业务平台</div>
        <div class="wp">
          <div class="content">
            <div class="item-list">
              <div class="small-wp">
                <div class="small-item item" @click="onJumpTo('dsp')">
                  <div class="detail">
                    <p class="name">天玑效果营销投放平台</p>
                  </div>
                </div>
                <div class="small-item item">
                  <div class="detail">
                    <p class="name">天枢品牌营销投放平台</p>
                  </div>
                </div>
              </div>
              <div class="normal-item item" @click="onJumpTo('ssp')">
                <div class="detail">
                  <p class="name">天璇流量交易平台</p>
                </div>
              </div>
              <div class="normal-item item" @click="onJumpTo('md')">
                <div class="detail">
                  <p class="name">即刻聚合平台</p>
                </div>
              </div>
            </div>
          </div>
          <div class="link-content">
            <div class="link-content-item">
              <img :src="arrowUp" alt="" />
              <div>数据赋能</div>
              <img :src="arrowUp" alt="" />
            </div>
            <div class="link-content-item">
              <img :src="arrowDown" alt="" />
              <div>数据回流</div>
              <img :src="arrowDown" alt="" />
            </div>
          </div>
          <div class="long-item item">
            <div class="bd-list">
              <div
                class="bd-item item"
                style="background: #fff"
                @click="onScroll('#abTest')"
              >
                <div class="detail">
                  <p class="name">实验平台</p>
                </div>
              </div>
              <div
                class="bd-item item"
                style="background: #fff"
                @click="onScroll('#dmp')"
              >
                <div class="detail">
                  <p class="name">DMP平台</p>
                </div>
              </div>
              <div
                class="bd-item item"
                style="background: #fff"
                @click="onScroll('#analysis')"
              >
                <div class="detail">
                  <p class="name">数据分析平台</p>
                </div>
              </div>
            </div>
            <div class="base">数据基座</div>
          </div>
        </div>
        <div class="desc bottom-desc">天权大数据中台</div>
      </div>
    </div>
    <div class="platform-desc">
      <div id="abTest" class="item-content">
        <div class="left">
          <p class="name">实验平台</p>
          <p class="desc desc-top">
            提供科学、可靠、高效的决策支持和创新驱动，帮助公
          </p>
          <p class="desc">司提升产品质量、优化产品设计和营销策略，降低风险</p>
          <p class="desc">和提高商业价值</p>
        </div>
        <img :src="ABTest" alt="" />
      </div>
      <div id="dmp" class="item-content">
        <div class="left">
          <p class="name">DMP平台</p>
          <p class="desc desc-top">
            通过对用户进行全方位的画像构建和分析，可以更加精
          </p>
          <p class="desc">准地定位用户的需求和偏好，制定个性化的营销策略，</p>
          <p class="desc">从而提高广告主的营销效果和转化率</p>
        </div>
        <img :src="DMP" alt="" />
      </div>
      <div id="analysis" class="item-content">
        <div class="left">
          <p class="name">数据分析平台</p>
          <p class="desc desc-top">
            四大全新能力，实时全面了解业务数据大盘、多维度关
          </p>
          <p class="desc">联分析、分钟级监控预警、规范化指标，优化资源配</p>
          <p class="desc">置，实现客户利益最大化</p>
        </div>
        <img :src="dataAnalysis" alt="" />
      </div>
    </div>
    <Cooperation title="与您的用户建立全新的链接" />
  </section>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import ABTest from '@/assets/bd/platform-AB-test.png'
import dataAnalysis from '@/assets/bd/platform-data.png'
import DMP from '@/assets/bd/platform-DMP.png'
import arrowUp from '@/assets/bd/arrow-up.png'
import arrowDown from '@/assets/bd/arrow-down.png'
import bdVideoImg from '@/assets/bd/bdVideoImg.png'
import Cooperation from '@/components/Cooperation.vue'
import { smoothScroll } from '@/utils/index'
import { host, isMobile } from '@/utils/index'

const router = useRouter()
const onJumpTo = (to: string) => {
  router.push(`/product/${to}`)
}

const videoSrc = `${host}/website/web/assets/bd/bdVideoBg.mp4`

window.onload = function () {
  const video = document.getElementById('myVideo') as HTMLVideoElement //获取，函数执行完成后local内存释放
  if (video) {
    video.controls = false
    video.play()
    if (video.paused) {
      //判断是否处于暂停状态
      video.play() //开启播放
    }
  }
}

const onScroll = (target: string) => {
  smoothScroll(target)
}
</script>

<style lang="scss" scoped>
$colorPorducItemBg: rgba(1, 1, 1, 0.85);
.title {
  height: 78px;
  line-height: 66px;
  font-size: 56px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}

.bd {
  width: 100%;
  background: #ffffff;
  .platform {
    text-align: center;
    padding: 120px 0;
    .name {
      height: 34px;
      line-height: 28px;
      font-size: 24px;
      font-weight: 400;
      color: #2a64c5;
    }
    .desc {
      margin: 8px 0 32px;
      height: 78px;
      line-height: 66px;
      font-size: 56px;
      font-weight: 500;
      color: #000000;
    }
    .effect {
      height: 40px;
      line-height: 40px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .business-wrapper {
    position: relative;
    background-color: #fff;

    .product-bg {
      font-size: 0;
      height: 812px;
      margin: 0 auto;
      min-width: 1440px;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      background-color: #000;
      video {
        width: 100%;
      }
    }
  }

  .business-main {
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    color: #000;
    padding: 50px 0;
    .desc {
      height: 47px;
      line-height: 47px;
      font-weight: 500;
      font-size: 28px;
      color: #fff;
    }
    .top-desc {
      margin-bottom: 10px;
    }

    .bottom-desc {
      margin-top: 10px;
    }
    .wp {
      @include width-1440;
      .content {
        .item-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: rgba(217, 217, 217, 0.4);
          padding: 32px;
          border-radius: 20px;
          .small-wp {
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
        .item {
          width: 338px;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: $border8;
          .icon {
            width: 44px;
            height: 44px;
          }
          .detail {
            .name {
              font-size: 24px;
              line-height: 40px;
              font-weight: 500;
            }
            .desc {
              margin-top: 10px;
              opacity: 80%;
              font-size: 500;
              line-height: 14px;
            }
          }
        }
        .small-item {
          height: 88px;
          padding: 0 47px 0 46px;
          background: #d9d9d9;
        }
        .normal-item {
          height: 200px;
          justify-content: center;
          background: #d9d9d9;
        }
      }

      .long-item {
        width: 100%;
        padding: 32px;
        justify-content: center;
        background: rgba(217, 217, 217, 0.4);
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        .bd-list {
          display: flex;
          justify-content: space-between;
          .bd-item {
            text-align: center;
            height: 88px;
            line-height: 88px;
            width: 337px;
            background: #d9d9d9;
            font-size: 24px;
            font-weight: 500;
            border-radius: 8px;
            cursor: pointer;
          }
        }
        .base {
          margin-top: 24px;
          font-size: 24px;
          width: 100%;
          height: 88px;
          line-height: 88px;
          font-weight: 500;
          background: #d9d9d9;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }

  .platform-desc {
    margin: 60px 0;
    .item-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        padding: 0 32px;
        .name {
          width: 489px;
          height: 54px;
          line-height: 54px;
          font-size: 32px;
          font-weight: 500;
          color: #000000;
        }
        .desc {
          line-height: 34px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
        .desc-top {
          margin-top: 12px;
        }
      }
      img {
        width: 553px;
        height: 335px;
        margin-left: 30px;
      }
    }
  }

  .link-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .link-content-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 60px;
      background: linear-gradient(
        180deg,
        rgba(0, 245, 151, 0.3) 0%,
        #00f597 50%,
        rgba(0, 245, 151, 0.3) 100%
      );
      div {
        margin: 0 16px;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
      }
      img {
        width: 40px;
        height: 40px;
      }
      &:last-child {
        margin-left: 180px;
      }
    }
  }
}
</style>
