import axios from 'axios'
import { ElMessage } from 'element-plus'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const http: any = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_API}`,
  timeout: 100000,
  withCredentials: true,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
  },
  cancelToken: source.token,
})

http.interceptors.request.use(
  async (config: any) => {
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  (res: any) => {
    const { code, msg } = res.data
    // 非正常返回值
    // if (code !== 200) {
    //   ElMessage({
    //     message: msg,
    //     type: 'error',
    //   })
    //   return res.data
    // }
    return res.data
  },
  async (error: any) => {
    const res = error.response
    const { msg } = res.data
    // ElMessage({
    //   message: msg,
    //   type: 'error',
    // })
    return res.data
  },
)

// 包装url
const rewriteUrl = (url: string) => {
  return url
}

const post = (url: string, params?: any, config?: object) => {
  return http.post(rewriteUrl(url), params, config)
}

const get = (url: string, params?: any, config?: object) => {
  return http.get(rewriteUrl(url), { params: params, ...config })
}

const all = (request: Array<any>) => {
  return axios.all(request)
}

const upload = (url: string, params?: object) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return http.post(rewriteUrl(url), params, config)
}

export { post, get, all, upload, http }
