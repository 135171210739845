const smoothScroll = (target: string) => {
  const element = document.querySelector(target) as HTMLElement
  // element.scrollIntoView({ behavior: 'smooth' })
  element.scrollIntoView()
}

const host =
  import.meta.env.MODE === 'production'
    ? window.location.origin
    : 'https://ubixai.com'
// : 'https://file-u1.ubixioe.com'

const getSource = (path: string) => {
  let source = 0
  switch (path) {
    case '/index':
      source = 13
      break
    case '/product/md':
      source = 12
      break
    case '/product/ssp':
      source = 9
      break
    case '/product/dsp':
      source = 7
      break
    case '/product/bd':
      source = 14
      break
    case '/product/gd':
      source = 8
      break
    case '/solution':
      source = 12
      break
    case '/contact':
    case '/company':
      source = 15
      break
    case '/contactMobile':
      source = 16
      break
    case '/contactQr':
      source = 17
      break
    case '/contactAuto':
      source = 18
      break
    default:
      break
  }
  return source
}

const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const isM = /(iphone|ipod|ipad|android|windows phone|blackberry|mobile)/.test(
    userAgent,
  )
  return isM
}
export { smoothScroll, host, getSource, isMobile }
