<template>
  <div class="contact">
    <h3>我们将尽快与您联系</h3>
    <el-form
      ref="formRef"
      class="contact-form"
      :model="formState"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item label="姓名" prop="userName" class="form-row-item">
        <el-input
          v-model="formState.userName"
          placeholder="请输入姓名"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone" class="form-row-item">
        <el-input
          v-model="formState.phone"
          placeholder="请输入手机号"
          autocomplete="off"
          maxlength="11"
        />
      </el-form-item>
      <el-form-item label="邮箱" prop="email" class="form-row-item">
        <el-input
          v-model="formState.email"
          placeholder="请输入邮箱号"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="公司名" prop="company" class="form-row-item">
        <el-input
          v-model="formState.company"
          placeholder="请输入公司名"
          autocomplete="off"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="补充说明" class="form-row-item" prop="other">
        <el-input
          v-model="formState.other"
          placeholder="请输入补充说明"
          autocomplete="off"
          maxlength="200"
        />
      </el-form-item>
      <el-form-item label="" class="form-row-item" prop="agreePrivicy">
        <el-checkbox v-model="formState.agreePrivicy"></el-checkbox>
        <span class="agree">我同意</span>
        <span class="privicy" @click="goToPrivacyPage">隐私政策</span>
      </el-form-item>
      <el-button
        type="primary"
        class="submit-button"
        :loading="loading"
        @click="onSubmit"
      >
        立即提交
      </el-button>
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { apiContact } from '@/service/api/contact'
import { ElMessage } from 'element-plus'
import { IContactApiParams } from '@/service/api/contact/type'
import { useRoute } from 'vue-router'
import { getSource } from '@/utils/index'

const modifyMeta = (scale: number) => {
  // 选择name属性为"viewport"的meta标签
  const meta = document.querySelector('meta[name="viewport"]')

  // 如果找到了这样的meta标签，则更改其content属性
  if (meta) {
    meta.setAttribute('content', `width=device-width, initial-scale=${scale}`)
  }
}

onMounted(() => {
  modifyMeta(1)
})

onUnmounted(() => {
  modifyMeta(0.1)
})

const route = useRoute()

const formRef = ref<FormInstance>()
const formState = ref<IContactApiParams>({
  userName: '',
  phone: '',
  email: '',
  company: '',
  cooperationType: undefined,
  other: '',
  requestInfo: '',
  agreePrivicy: false,
})
const loading = ref(false)
const validatePhone = (rule: any, value: any, callback: any) => {
  const reg = /^1[3456789]\d{9}$/
  if (value === '') {
    callback(new Error('请输入手机号！'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号！'))
  } else {
    callback()
  }
}

const validateEmail = (rule: any, value: any, callback: any) => {
  const reg =
    /^([0-9A-Za-z]+[0-9A-Za-z\-_.]+)@([a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]{1,}(\.[a-zA-Z0-9-_]{1,})?)$/g
  if (value === '') {
    callback(new Error('请输入邮箱！'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确格式的邮箱！'))
  } else {
    callback()
  }
}

const validatePrivicy = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('请勾选隐私政策'))
  } else {
    callback()
  }
}

const rules = reactive<FormRules>({
  userName: [
    {
      required: true,
      message: '请输入姓名',
      pattern: '[^ \x22]+',
    },
  ],
  phone: [{ required: true, validator: validatePhone }],
  email: [{ required: true, validator: validateEmail }],
  company: [
    {
      required: true,
      message: '请输入公司名',
      pattern: '[^\x22]+',
      trigger: 'blur',
    },
  ],
  agreePrivicy: [{ required: true, validator: validatePrivicy }],
})
const onSubmit = async () => {
  if (!formRef.value) {
    return
  }
  await formRef.value.validate((valid, fields) => {
    if (valid) {
      const host = window.location.host
      formState.value.requestInfo = JSON.stringify({ host })
      // 此页面指给微信使用可固定写死cooperationType
      formState.value.cooperationType = getSource(route.path)
      onSubmitAction(formState.value)
    }
  })
}

const onSubmitAction = async (params: IContactApiParams) => {
  try {
    loading.value = true
    const res = await apiContact(params)
    if (res.code === 200) {
      ElMessage({
        message: '提交成功',
        type: 'success',
      })
      formRef.value?.resetFields()
    } else {
      ElMessage({
        message: '提交失败，请重试。',
        type: 'error',
      })
    }
  } catch (e) {
    ElMessage({
      message: '提交失败，请重试。',
      type: 'error',
    })
  } finally {
    loading.value = false
  }
}

const goToPrivacyPage = () => {
  // router.push('/privacy')
  const host = window.origin
  window.open(`${host}/ubix_privacy.html`, '_blank')
}
</script>

<style lang="scss" scoped>
$colorFocus: rgba(42, 100, 197, 1);

h3 {
  height: 76px;
  line-height: 76px;
  font-size: $titleFont1;
  font-weight: $mediumWeight;
  color: $colorBlack1;
  text-align: center;
  background-color: $colorWhite1;
  margin-top: 20px;
}
.contact {
  // width: 100%;
  // width: 1440px;
  margin: 0 auto;
  background-color: $colorWhite1;

  .contact-form {
    background-color: $colorWhite1;
    padding: 0 20px;
    padding-bottom: 100px;
    // box-shadow: 0px 0px 100px 0px rgba(103, 123, 147, 0.25);
    .form-row-item {
      display: block;
      margin-bottom: 24px;
      :deep(.el-form-item__label) {
        color: $colorBlack1;
        height: $font6;
        line-height: $font6;
        margin-bottom: 8px;
      }
      :deep(.el-form-item__content) {
        .el-input {
          height: 40px;
        }
        .el-input__wrapper {
          padding: 0;
          box-shadow: 0 0 0 1px rgba(220, 223, 230, 1) inset;
        }
        .el-input__wrapper.is-focus {
          box-shadow: 0 0 0 1px $colorFocus inset;
        }
        .el-input__inner {
          height: 40px;
          padding-left: 16px;
          color: $colorBlack3;
        }
        .el-form-item__error {
          margin: 4px;
        }

        .el-checkbox {
          margin-right: 8px;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: $colorFocus;
          border-color: $colorFocus;
        }

        .el-checkbox__inner:hover {
          border-color: $colorFocus;
        }
      }

      .agree {
        color: $colorBlack3;
      }

      .privicy {
        color: $colorFocus;
        margin-left: 2px;
        cursor: pointer;
      }
    }
    .submit-button {
      width: 100%;
      height: 40px;
      background: $colorBgDark;
      border-radius: 4px;
      color: $colorWhite1;
      border: 0;
      &:hover {
        background: #666666;
      }
    }
  }
}
</style>
