<template>
  <section class="md">
    <div class="banner">
      <div class="wp">
        <p class="tip">即刻聚合平台</p>
        <h3 class="title">开发者收益最大化</h3>
        <p class="sub-title top">
          完全中立的聚合SDK平台，用领先的产品能力优化广告交易效率，
        </p>
        <p class="sub-title">
          利用上游预算资源及独家权限，实现开发者收益最大化
        </p>
        <span class="more" @click="onJumpToConnect">
          立即开始
          <span class="bg"></span>
        </span>
      </div>
    </div>
    <div class="advantage">
      <div class="wp">
        <p class="title">即刻聚合产品优势</p>
        <ul class="way-list">
          <li v-for="item in wayList" :key="item.key">
            <img :src="item.image" class="image" />
            <p class="link-content">
              <span class="link" @click="onScroll(item.link)">
                {{ item.value }}
              </span>
              <img :src="rightIcon" class="link-image" />
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div id="increase" name="increase" class="increase">
      <div class="increase-main">
        <div class="content">
          <p class="tip">降本增效</p>
          <h4 class="header">通过聚合管理提升结算效率和收益</h4>
          <ul class="increase-list">
            <li v-for="item in increaseList" :key="item.key" class="item">
              <img :src="item.image" class="image" />
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.descFirstLine }}</p>
              <p class="desc">{{ item.descSecondLine }}</p>
            </li>
          </ul>
          <p class="link-content">
            不知道如何配置您的变现方案?
            <span class="link" @click="onJumpToConnect">从联系我们开始</span>
            <img :src="rightIcon" class="link-image" />
          </p>
        </div>
      </div>
    </div>
    <div id="earning" name="earning" class="earning">
      <div class="earning-main">
        <div class="content">
          <p class="tip">收益提升</p>
          <h4 class="header">全生命周期的广告优化 收益高效稳定</h4>
          <ul class="earning-list">
            <li
              v-for="(item, index) in earningList"
              :key="item.key"
              class="item"
              :class="`item${index}`"
            >
              <p class="title">{{ item.title }}</p>
              <img :src="item.image" class="image" />
              <p class="desc">{{ item.descFirstLine }}</p>
              <p class="desc">{{ item.descSecondLine }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="save" class="save">
      <div class="save-main">
        <div class="content">
          <p class="tip">安全合规</p>
          <h4 class="header">即刻聚合SDK获官方安全认证</h4>
          <div class="detail">
            <div class="left">
              <p class="desc">
                优比客思(UBiX)下即刻聚合SDK产品，已通过公安部检验检测、中国信通院SDK安全专项评测；
              </p>
              <p class="desc">
                即刻聚合SDK双端版本，在信息数据收集处理方面均合法合规，获得安全专项评测证书;
              </p>
              <p class="desc">
                即刻聚合SDK于2023年入选中国信通院“星熠”绿色SDK案例
              </p>
              <span class="more" @click="onJumpToCompany">查看更多</span>
            </div>
            <div class="right">
              <img :src="certificateIcon" class="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="save">
      <div class="save-main">
        <div class="content">
          <p class="tip">安全合规</p>
          <h4 class="header">精细化审核确保广告安全</h4>
          <div class="detail">
            <div class="left">
              <p class="title">全渠道支持素材审核，为广告安全和用户体验护航</p>
              <ul class="advantage-list">
                <li v-for="item in advantageList" :key="item.key" class="item">
                  <img :src="checkIcon" class="image" />
                  <p class="title">{{ item.title }}</p>
                </li>
              </ul>
            </div>
            <div class="right">
              <img :src="phoneIcon" class="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="user" class="save">
      <div class="save-main">
        <div class="content">
          <p class="tip">用户体验</p>
          <h4 class="header">高占比的自渲染广告模版适配</h4>
          <ul class="adType-list">
            <li v-for="item in adTypeList" :key="item.key" class="item">
              <img :src="item.image" class="image" />
              <p class="title">{{ item.title }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Cooperation title="将您的广告收入提升到新的高度" />
  </section>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import Cooperation from '@/components/Cooperation.vue'
import rightIcon from '@/assets/home/rightIcon.png'
import userIcon from '@/assets/md/userIcon.png'
import moneyIcon from '@/assets/md/moneyIcon.png'
import upIcon from '@/assets/md/upIcon.png'
import saveIcon from '@/assets/md/saveIcon.png'
import dataIcon from '@/assets/md/dataIcon.png'
import coinIcon from '@/assets/md/coinIcon.png'
import lineIcon from '@/assets/md/lineIcon.png'
import timeIcon from '@/assets/md/timeIcon.png'
import certificateIcon from '@/assets/md/certificateIcon.png'
import phoneIcon from '@/assets/md/phoneIcon.png'
import checkIcon from '@/assets/md/checkIcon.png'
import functionIcon from '@/assets/md/functionIcon.png'
import biddingIcon from '@/assets/md/biddingIcon.png'
import bannerIcon from '@/assets/md/bannerIcon.png'
import screenIcon from '@/assets/md/screenIcon.png'
import videoIcon from '@/assets/md/videoIcon.png'
import motivationalIcon from '@/assets/md/motivationalIcon.png'
import insertIcon from '@/assets/md/insertIcon.png'
import infoIcon from '@/assets/md/infoIcon.png'
import { smoothScroll, getSource } from '@/utils/index'

const router = useRouter()
const route = useRoute()
const wayList = [
  {
    key: '1',
    image: upIcon,
    value: '降本增效',
    link: '#increase',
  },
  {
    key: '2',
    image: moneyIcon,
    value: '提升收益',
    link: '#earning',
  },
  {
    key: '3',
    image: saveIcon,
    value: '安全合规',
    link: '#save',
  },
  {
    key: '4',
    image: userIcon,
    value: '用户体验',
    link: '#user',
  },
]

const increaseList = [
  {
    key: '1',
    image: dataIcon,
    title: '降低对接成本',
    descFirstLine: '多预算源SDK一次性兼容',
    descSecondLine: '多广告形式及渲染适配支持',
  },
  {
    key: '2',
    image: lineIcon,
    title: '降低运营成本',
    descFirstLine: '数据报表整合呈现',
    descSecondLine: '运营经验优选复用',
  },
  {
    key: '3',
    image: timeIcon,
    title: '提高迭代效率',
    descFirstLine: '产品选代SDK版本更新',
    descSecondLine: '政策法规策略调整更新',
  },
  {
    key: '4',
    image: coinIcon,
    title: '提高结算效率',
    descFirstLine: '周期结算数据自动更新呈现',
    descSecondLine: '结算开票付款流程一体化',
  },
]

const earningList = [
  {
    key: '1',
    image: functionIcon,
    title: '数据为依托 产品赋能',
    descFirstLine: '通过强大产品能力和多样运营策略，完成你',
    descSecondLine: '对聚合SDK平台的所有想象',
  },
  {
    key: '2',
    image: biddingIcon,
    title: '一流的头层竞价技术',
    descFirstLine: '并行实时竞价，价高者得；自动化竞价，节省运营成本',
    descSecondLine: '数据透明，开发者可获取单UV价值',
  },
]

const advantageList = [
  {
    key: '1',
    title: '把控预算源',
  },
  {
    key: '2',
    title: '智能AI识别',
  },
  {
    key: '3',
    title: '优化产品力',
  },
  {
    key: '4',
    title: '专业审核团队',
  },
  {
    key: '5',
    title: '提升运营经验',
  },
]

const adTypeList = [
  {
    key: '1',
    title: '开屏广告',
    image: screenIcon,
  },
  {
    key: '2',
    title: '信息流广告',
    image: infoIcon,
  },
  {
    key: '3',
    title: '原生视频广告',
    image: videoIcon,
  },
  {
    key: '4',
    title: '插屏广告',
    image: insertIcon,
  },
  {
    key: '5',
    title: '激励视频广告',
    image: motivationalIcon,
  },
  {
    key: '6',
    title: '横幅广告',
    image: bannerIcon,
  },
]

const onJumpToCompany = () => {
  router.push('/company?tab=News')
}

const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}

const onScroll = (target: string) => {
  smoothScroll(target)
}
</script>

<style lang="scss" scoped>
.md {
  .banner {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        height: 34px;
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 34px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
      }
      .sub-title {
        @include sub-title;
        height: 40px;
        &.top {
          margin-top: 32px;
        }
      }
      .more {
        margin-top: 56px;
        @include start-btn;
        .bg {
          @include start-btn-bg;
        }
      }
    }
  }
  .advantage {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 84px;
      padding-bottom: 84px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        @include title-black;
        margin-bottom: 72px;
      }
      .way-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .image {
          width: 70px;
          height: 60px;
          vertical-align: bottom;
        }
        .link-content {
          height: 24px;
          margin-top: 25px;
          display: flex;
          align-items: center;
          .link {
            font-size: $titleFont1;
            font-weight: $mediumWeight;
            color: $colorActive;
            line-height: 1;
            cursor: pointer;
            border-bottom: 3px solid transparent;
            &:hover {
              border-bottom: 3px solid $colorActive;
            }
          }
          .link-image {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  .increase {
    position: relative;
    height: 674px;
    background-color: $colorBgLight;
    .increase-main {
      @include width-1440;
      height: 100%;
      padding-top: 84px;
      padding-bottom: 60px;
      .content {
        width: 100%;
        height: 100%;
        text-align: center;
        .tip {
          height: 40px;
          font-size: $titleFont1;
          color: $colorActive;
          line-height: 40px;
          margin-bottom: 8px;
        }
        .header {
          @include title-black;
          margin-bottom: 72px;
        }
        .increase-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .item {
            text-align: left;
            .image {
              width: 104px;
              height: 104px;
              margin-left: 35px;
              vertical-align: bottom;
            }
            .title {
              height: 47px;
              line-height: 47px;
              font-size: 28px;
              color: $colorBlack1;
              margin-top: 32px;
              margin-bottom: 12px;
            }
            .desc {
              height: 30px;
              font-size: $font4;
              color: $colorBlack2;
              line-height: 30px;
              margin-bottom: 4px;
            }
          }
        }
        .link-content {
          height: 25px;
          line-height: 25px;
          font-size: $font4;
          color: $colorBlack2;
          margin-top: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          .link {
            margin-left: 8px;
            font-weight: $mediumWeight;
            color: $colorActive;
            cursor: pointer;
          }
          .link-image {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  .earning {
    position: relative;
    background-color: $colorBgWhite;
    .earning-main {
      @include width-1440;
      height: 100%;
      padding-top: 84px;
      padding-bottom: 72px;
      .content {
        width: 100%;
        height: 100%;
        text-align: center;
        .tip {
          height: 40px;
          font-size: $titleFont1;
          color: $colorActive;
          line-height: 40px;
          margin-bottom: 8px;
        }
        .header {
          @include title-black;
          margin-bottom: 48px;
        }
        .earning-list {
          width: 100%;
          padding-left: 90px;
          padding-right: 65px;
          display: flex;
          justify-content: space-between;
          .item {
            text-align: center;
            .title {
              height: 34px;
              line-height: 34px;
              font-size: $titleFont1;
              color: $colorBlack1;
              margin-bottom: 29px;
            }
            .desc {
              height: 32px;
              font-size: $font4;
              color: $colorBlack2;
              line-height: 32px;
            }
            .image {
              margin-bottom: 20px;
              vertical-align: bottom;
            }
          }
          .item0 {
            .image {
              width: 336px;
              height: 336px;
            }
          }
          .item1 {
            .image {
              width: 553px;
              height: 336px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  .save {
    position: relative;
    background-color: $colorBgWhite;
    .save-main {
      @include width-1440;
      height: 100%;
      padding-top: 84px;
      padding-bottom: 60px;

      .content {
        width: 100%;
        height: 100%;
        text-align: center;
        .tip {
          height: 40px;
          font-size: $titleFont1;
          color: $colorActive;
          line-height: 40px;
          margin-bottom: 8px;
        }
        .header {
          @include title-black;
          margin-bottom: 48px;
        }
        .detail {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            width: 553px;
            text-align: left;
            .desc {
              color: $colorBlack2;
              line-height: 40px;
            }
            .more {
              margin-top: 32px;
              @include start-btn;
            }
            .title {
              color: $colorBlack1;
              line-height: 40px;
            }
            .advantage-list {
              margin-top: 32px;
              display: flex;
              flex-wrap: wrap;

              .item {
                width: 258px;
                height: 61px;
                display: flex;
                align-items: center;
                .image {
                  width: 32px;
                  height: 32px;
                  vertical-align: bottom;
                }
                .title {
                  color: $colorBlack2;
                  margin-left: 16px;
                }
              }
            }
          }
          .right {
            width: 553px;
            .image {
              width: 100%;
              height: 100%;
              vertical-align: bottom;
            }
          }
        }
        .adType-list {
          display: flex;
          justify-content: space-between;
          .item {
            width: 164px;
            .image {
              width: 164px;
              vertical-align: bottom;
            }
            .title {
              color: $colorBlack1;
              height: 34px;
              line-height: 34px;
              margin-top: 24px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  .cooperation {
    position: relative;
    background-color: $colorBgDark;
    height: 343px;
    box-shadow: inset 0px -1px 0px 0px rgba(217, 217, 217, 0.08);
    .wp {
      @include width-1440;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        @include title-white;
        margin-top: 78px;
        margin-bottom: 52px;
        text-align: center;
      }
      .more {
        @include start-btn;
        color: $colorBlack1;
        background-color: $colorBgWhite;
        &:hover {
          background: $colorHoverBg;
        }
      }
    }
  }
}
</style>
