<template>
  <div class="technology">
    <div class="technology-main">
      <div class="content">
        <img :src="technologyInnerBg" class="inner-image" />
        <h4 class="header">产品技术创新是我们的DNA</h4>
        <div style="position: relative">
          <div
            v-for="(item, index) in technologyList"
            :key="item.key"
            class="item"
            :class="`item${index}`"
          >
            <p class="count-content">
              <span class="number">{{ item.num }}</span>
              <span class="number number-word">{{ item.numWord }}</span>
              <span v-if="item.subNum" class="number number-word">
                {{ item.subNum }}
              </span>
              <span class="symbol-content">
                <span class="symbol">{{ item.symbol }}</span>
                <span class="symbol-word">{{ item.symbolWord }}</span>
              </span>
            </p>
            <p class="name">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import technologyInnerBg from '@/assets/home/innerIcon.png'

const technologyList = [
  {
    key: '1',
    value: 'SDK 流量规模',
    num: '100',
    numWord: '亿',
    symbol: '+',
    symbolWord: '/ 天',
  },
  {
    key: '2',
    value: '算法处理特征规模',
    num: '100',
    numWord: '亿',
    symbol: '+',
  },
  {
    key: '3',
    value: '触达用户规模',
    num: '10',
    numWord: '亿',
    symbol: '+',
  },
  {
    key: '4',
    value: '触达智能设备规模',
    num: '19',
    numWord: '亿',
    symbol: '+',
  },
  {
    key: '5',
    value: '支持智能设备系统',
    num: 'IOS',
    numWord: '安卓',
    subNum: '鸿蒙',
    symbol: '',
  },
  {
    key: '6',
    value: '数据新增规模',
    num: '15',
    numWord: 'T',
    symbol: '+',
    symbolWord: '/ 天',
  },
]
</script>

<style lang="scss" scoped>
.technology {
  position: relative;
  height: 674px;
  background: url(@/assets/home/technologyBg.png) no-repeat center;
  background-color: $colorBgWhite;
  background-size: cover;
  .technology-main {
    @include width-1440;
    height: 100%;
    padding: 0;
    .content {
      width: 100%;
      height: 100%;
      text-align: center;
      position: relative;
      .inner-image {
        position: absolute;
        width: 1440px;
        left: 0;
        top: 330px;
      }
      .header {
        @include title-black;
        height: 164px;
        padding-top: 84px;
      }
      .item {
        text-align: center;
        .count-content {
          height: 56px;
          line-height: 56px;
          display: flex;
          justify-content: center;
          .number {
            font-size: 40px;
            color: $colorBlack1;
            font-weight: $boldWeight;
          }
          .number-word {
            margin-left: 7px;
          }
          .sub-number {
            font-size: 36px;
            color: $colorBlack1;
            font-weight: $mediumWeight;
            margin-left: 7px;
          }
          .symbol-content {
            font-size: $font4;
            color: $colorBlack1;
            font-weight: $mediumWeight;
            position: relative;
            top: -8px;
            left: 7px;
            .symbol {
              font-size: $titleFont1;
              position: relative;
              top: -2px;
            }
            .symbol-word {
              margin-left: 7px;
            }
          }
        }

        .name {
          height: 28px;
          margin-top: 6px;
          font-size: $font5;
          line-height: 28px;
          color: $colorBlack3;
        }
      }
      .item0 {
        position: absolute;
        top: 122px;
        left: 152px;
      }
      .item1 {
        position: absolute;
        top: 346px;
        left: 407px;
      }
      .item2 {
        position: absolute;
        top: 72px;
        left: 517px;
      }
      .item3 {
        position: absolute;
        top: 138px;
        left: 794px;
      }
      .item4 {
        position: absolute;
        top: 346px;
        left: 846px;
      }
      .item5 {
        position: absolute;
        top: 72px;
        left: 1084px;
      }
    }
  }
}
</style>
