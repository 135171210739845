<template>
  <div class="banner">
    <div class="banner-text">
      <div class="wp">
        <h3 class="title">
          数字智能时代 数据和算法驱动的端到端AI全智能营销平台
        </h3>
        <h3 class="sub-title">
          优比客思(UBiX)引领未来，连接所有可以连接的智能设备，为广告主提供AI智能化精准营销解决方案，为开发者提供安全高效AI全智能的变现方案
        </h3>
        <span class="more" @click="onJumpToConnect">
          立即开始
          <span class="bg"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import { getSource } from '@/utils/index'

const router = useRouter()
const route = useRoute()
const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  overflow: hidden;
  background-color: $colorWhite1;
  .banner-text {
    .wp {
      @include width-1440;
      margin-top: 133px;
      margin-bottom: 120px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        @include title-black;
        width: 991px;
        height: 156px;
        margin-bottom: 32px;
        text-align: center;
        display: flex;
        align-items: center;
      }
      .sub-title {
        @include sub-title;
        width: 890px;
        height: 80px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 69px;
      }
      .more {
        @include start-btn;
        .bg {
          @include start-btn-bg;
        }
      }
    }
  }
}
</style>
