<template>
  <div
    ref="scrollBody"
    class="custom-list"
    @mouseenter="mouseenterFunc"
    @mouseleave="mouseleaveFunc"
    @mousewheel="mousewheelFunc"
  >
    <div
      ref="listBody"
      class="list-body"
      :class="{
        'list-body2': isHorizontal,
      }"
      :style="{ transform: getScrollDistance() }"
    >
      <slot></slot>
    </div>
    <div
      v-if="isCanScroll"
      ref="tBody"
      class="list-body"
      :class="{
        'list-body2': isHorizontal,
      }"
      :style="{ transform: getScrollDistance() }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VScroll',
  props: {
    steep: {
      //滚动速度
      type: Number,
      default: 1,
    },
    scrollDirection: {
      //滚动方向
      type: String,
      default: 'top',
    },
    isRoller: {
      //是否可以滑轮滚动
      type: Boolean,
      default: true,
    },
    rollerScrollDistance: {
      //滑轮滚动的距离
      type: Number,
      default: 20,
    },
    data: Array,
  },
  data() {
    return {
      scrollDistance: 0, //滚动距离
      tDom: '', //复制的容器
      bodyHeight: 0, //滚动容器高度
      bodyWidth: 0, //滚动容器宽度
      listHeight: 0, //列表高度
      listWidth: 0, //列表宽度
      isStop: false,
      animationFrame: null,
      isCanScroll: true,
    }
  },
  computed: {
    isHorizontal() {
      return this.scrollDirection === 'left' || this.scrollDirection === 'right'
    },
  },
  watch: {
    data: {
      handler() {
        this.initData()
      },
      deep: true,
    },
  },

  mounted() {
    //初始化页面数据
    this.initData()
    window.onresize = () => {
      this.start()
    }
  },
  methods: {
    start() {
      //判断是否可以滚动函数
      const isScrollFunc = (bodySize, listSize) => {
        if (bodySize > listSize) {
          this.scrollDistance = 0
          this.isCanScroll = false
        }
      }

      this.isStop = false
      //判断是否可以滚动
      if (!this.isHorizontal) {
        isScrollFunc(this.bodyHeight, this.listHeight)
      } else {
        isScrollFunc(this.bodyWidth, this.listWidth)
      }
      if (this.isCanScroll) {
        this.run()
      }
    },
    run() {
      //清空动画
      // this.clearAnimation()

      this.animationFrame = window.requestAnimationFrame(() => {
        //滚动主逻辑函数
        const main = (listSize, bodySize) => {
          const scrollDistance = Math.abs(this.scrollDistance)
          if (this.scrollDistance < 0) {
            const cc = 2 * listSize - bodySize
            if (scrollDistance + 10 >= cc) {
              this.scrollDistance = -(listSize - bodySize - 10)
            }
          } else {
            // this.scrollDistance = 0
            this.scrollDistance = -listSize
          }
        }

        //根据滚动方向判断使用高度或宽度控制效果
        if (!this.isHorizontal) {
          main(this.listHeight, this.bodyHeight)
        } else {
          main(this.listWidth, this.bodyWidth)
        }
        //判断滚动值
        if (!this.isStop) {
          if (
            this.scrollDirection === 'top' ||
            this.scrollDirection === 'left'
          ) {
            this.scrollDistance -= this.steep
          } else if (
            this.scrollDirection === 'bottom' ||
            this.scrollDirection === 'right'
          ) {
            this.scrollDistance += this.steep
          }
          this.run()
        }
      })
    },
    //停止滚动
    stop() {
      this.isStop = true
      this.clearAnimation()
    },
    //初始化数值
    initData() {
      this.$nextTick(() => {
        this.scrollDistance = 0
        this.isCanScroll = true
        this.bodyHeight = this.$refs.scrollBody.clientHeight
        this.bodyWidth = this.$refs.scrollBody.clientWidth
        this.listHeight = this.$refs.listBody.clientHeight
        this.listWidth = this.$refs.listBody.clientWidth

        if (
          (this.bodyHeight !== 0 &&
            this.listHeight !== 0 &&
            this.listHeight >= this.bodyHeight) ||
          (this.bodyWidth !== 0 &&
            this.listWidth !== 0 &&
            this.listWidth >= this.bodyWidth)
        ) {
          this.isCanScroll = true
          this.start()
        } else {
          this.isCanScroll = false
        }
      })
    },
    //获取滚动样式
    getScrollDistance() {
      let c
      if (!this.isHorizontal) {
        c = 'translate(0px, ' + this.scrollDistance + 'px)'
      } else {
        c = 'translate(' + this.scrollDistance + 'px,0px)'
      }
      return c
    },

    clearAnimation() {
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
        this.animationFrame = null
      }
    },
    mouseenterFunc() {
      this.$emit('mouseenter', this.stop)
    },
    mouseleaveFunc() {
      this.$emit('mouseleave', this.start)
    },
    mousewheelFunc(e) {
      if (!this.isCanScroll || !this.isRoller) {
        return false
      }
      // //滚动方向为横向时禁用鼠标滚轮滚动
      // if (this.scrollDirection === "left" || this.scrollDirection === "right") {
      //   return;
      // }
      const dis = e.deltaY
      if (dis > 0) {
        this.scrollDistance -= this.rollerScrollDistance
      } else {
        this.scrollDistance += this.rollerScrollDistance
      }
      this.run()
    },
  },
}
</script>

<style scoped>
.custom-list {
  white-space: nowrap;
  font-size: 0;
  overflow: hidden;
}
.list-body {
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  transition: all 0s linear;
}
.list-body2 {
  display: inline-block;
}
</style>
