<template>
  <div class="ssp">
    <div class="platform">
      <p class="name">天璇流量交易平台</p>
      <p class="desc">数据和算法驱动的数字化广告交易平台</p>
      <p class="effect">
        链接上下游的一站式广告交易平台，数据和算法全面驱动，创造更多价值
      </p>
      <p class="btn btn-black" @click="onJumpToConnect">
        立即开始
        <span class="bg"></span>
      </p>
    </div>

    <div class="content">
      <div class="title">海量优质媒体资源 全场景多方位覆盖用户</div>
    </div>
    <MediaResource />
    <div class="mode-container">
      <div class="title">支持多样的交易模式</div>
      <div class="trade-method">
        <img :src="tradeMode" class="mode-img" />
        <div class="mode-desc">
          <div class="mode-item">
            <p class="sub-title">私有交易市场</p>
            <p class="sub-desc">
              固定价格高层级垂类媒体流量支持，高效丰富的标签定向及展示效率保证
            </p>
          </div>
          <div class="mode-item bottom-item">
            <p class="sub-title">公开交易</p>
            <p class="sub-desc">
              海量竞价下沉媒体流量接入，支持不同成本需求的综合预算投放
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="link-container">
      <div class="title special-title">数据和算法加持 精准高效触达目标人群</div>
      <div class="link-content">
        <p>UBiX DMP</p>
        <p>数据赋能</p>
      </div>
      <div class="desc-content">
        <div class="left">
          <p>覆盖90%+移动端用户</p>
          <p>自建500+用户基础属性</p>
          <p>及兴趣爱好标签</p>
        </div>
        <div class="middle">
          <p>比媒体更了解用户</p>
          <p>比广告主更容易找到受众</p>
          <p>比用户更清楚自己想要的商品</p>
        </div>
        <div class="right">
          <p>精准高效触达目标人群</p>
          <p>高效达成营销目标</p>
        </div>
      </div>
    </div>
    <Cooperation title="开启一站式数字化广告交易" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import tradeMode from '@/assets/ssp/trade-mode.png'
import MediaResource from '@/components/MediaResource.vue'
import Cooperation from '@/components/Cooperation.vue'
import { getSource } from '@/utils'

const router = useRouter()
const route = useRoute()
const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}
</script>

<style lang="scss" scoped>
.title {
  height: 78px;
  line-height: 66px;
  font-size: 56px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}

.btn {
  width: 168px;
  height: 57px;
  margin: 56px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;
  font-weight: 400;
  font-size: 18px;
}

.ssp {
  width: 100%;
  background: #ffffff;

  .platform {
    text-align: center;
    padding: 120px 0;
    .name {
      height: 34px;
      line-height: 28px;
      font-size: 24px;
      font-weight: 400;
      color: #2a64c5;
    }
    .desc {
      margin: 8px 0 32px;
      height: 78px;
      line-height: 66px;
      font-size: 56px;
      font-weight: 500;
      color: #000000;
    }
    .effect {
      height: 40px;
      line-height: 40px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    .btn-black {
      @include start-btn;
      background: #272727;
      color: #fff;
      .bg {
        @include start-btn-bg;
      }
    }
  }

  .content {
    margin: 84px 0 48px;
  }

  .mode-container {
    text-align: center;
    margin: 84px 0 60px;

    .trade-method {
      display: flex;
      justify-content: center;
      margin-top: 48px;

      .mode-img {
        width: 553px;
        height: 364px;
        margin-right: 30px;
      }
      .mode-desc {
        .mode-item {
          padding: 24px 32px;
          text-align: left;

          .sub-title {
            width: 489px;
            height: 54px;
            line-height: 54px;
            font-size: 32px;
            font-weight: 500;
            color: #000000;
          }
          .sub-desc {
            width: 489px;
            margin-top: 12px;
            height: 68px;
            line-height: 34px;
            font-size: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }

  .link-container {
    padding: 84px 0 60px;
    background: #272727;
    box-shadow: inset 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
    opacity: 1;
    .special-title {
      color: #fff;
      margin-bottom: 123px;
    }
    .link-content {
      width: 961px;
      height: 250px;
      margin: 0 auto;
      position: relative;
      background-image: url(@/assets/ssp/link.png);
      background-size: cover;
      background-repeat: no-repeat;

      :first-child {
        position: absolute;
        top: 122px;
        left: 388px;
        height: 39px;
        line-height: 33px;
        font-size: 28px;
        font-weight: 500;
        color: #000000;
      }

      :last-child {
        position: absolute;
        top: 165px;
        left: 422px;
        height: 25px;
        line-height: 21px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .desc-content {
      width: 1022px;
      margin: 42px auto 0;
      display: flex;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      .middle {
        margin: 0 172px 0 180px;
      }
    }
  }
}
</style>
