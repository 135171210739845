<template>
  <footer class="footer">
    <div class="top footer-main">
      <img class="logo" width="243" height="200" :src="logo" alt="" />
      <dl v-for="item in navList" :key="item.key" class="nav">
        <dt>{{ item.value }}</dt>
        <dd v-for="m in item.dropMenuList" :key="m.key" @click="goTo(m.path)">
          {{ m.value }}
        </dd>
      </dl>
      <div class="contact">
        <div class="btn" @click="onConnect">联系我们</div>
        <img class="qrCode" :src="qrCode" alt="" width="60" height="60" />
        <div class="tip">
          <img :src="wechat" width="32" height="32" alt="" />
          <span>关注UBiX获取最新动态</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-main">
        <a class="icp" target="_blank" href="https://beian.miit.gov.cn/">
          {{ icp }}
        </a>
        <a class="tip" @click="goToPrivacyPage">隐私政策</a>
        <span class="tip">上海优比客思科技有限公司保留所有权利</span>
        <span class="copyright">
          <span>Copyright</span>
          <img :src="copyrightIcon" width="20" alt="" />
          <span>{{ copyRight }}</span>
        </span>
      </div>
    </div>
    <el-backtop
      :right="100"
      :bottom="100"
      style="color: rgba(42, 100, 197, 1)"
    />
  </footer>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import logo from '@/assets/common/logo-footer.png'
import copyrightIcon from '@/assets/common/cIcon.png'
import wechat from '@/assets/common/wechat.png'
import qrCode from '@/assets/common/qrcode.png'
import { getSource } from '@/utils/index'

const route = useRoute()
const router = useRouter()

const navList = [
  {
    key: '2',
    value: '产品',
    path: '',
    dropMenuList: [
      {
        key: '13',
        value: '天玑效果营销投放平台',
        path: '/product/dsp',
      },
      {
        key: '12',
        value: '天璇流量交易平台',
        path: '/product/ssp',
      },
      {
        key: '11',
        value: '即刻聚合平台',
        path: '/product/md',
      },
      {
        key: '15',
        value: '天权大数据中台',
        path: '/product/bd',
      },
    ],
  },
  {
    key: '3',
    value: '解决方案',
    path: '/solution',
    dropMenuList: [
      {
        key: '31',
        value: '用户增长',
        path: '/product/dsp',
      },
      {
        key: '32',
        value: '再营销',
        path: '/product/dsp2',
      },
      {
        key: '33',
        value: 'ADX流量交易',
        path: '/product/ssp',
      },
      {
        key: '34',
        value: '流量变现',
        path: '/solution',
      },
    ],
  },
  {
    key: '4',
    value: '公司',
    path: '/company',
    dropMenuList: [
      {
        key: '41',
        value: '关于我们',
        path: '/company?tab=About',
      },
      {
        key: '42',
        value: '公司文化',
        path: '/company?tab=Culture',
      },
      {
        key: '43',
        value: '职业机会',
        path: '/company?tab=Jobs',
      },
      {
        key: '44',
        value: '公司动态',
        path: '/company?tab=News',
      },
    ],
  },
]
const icp = ref('沪ICP备2021027254号-2')
const copyRight = computed(() => {
  const newDate = new Date()
  return `${newDate.getFullYear()} UBiX`
})

onMounted(() => {
  const urlHost = window.location.host
  if (urlHost.includes('ubixai.com')) {
    icp.value = '沪ICP备2021027254号-3'
  } else if (urlHost.includes('ubix-ai.com')) {
    icp.value = '沪ICP备2021027254号-1'
  } else if (urlHost.includes('ubixai.cn')) {
    icp.value = '沪ICP备2021036126号-1'
  }
})

const goTo = (path: string) => {
  router.push(path)
}

const onConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}

const goToPrivacyPage = () => {
  // router.push('/privacy')
  const host = window.origin
  window.open(`${host}/ubix_privacy.html`)
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: $colorBgDark1;

  .top {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .footer-main {
    @include width-1440;
    display: flex;
    justify-content: space-between;
    color: $colorWhite2;

    .nav {
      dl {
        display: flex;
        flex-direction: column;
      }
      dt {
        color: $colorWhite1;
        font-size: $font3;
        margin-bottom: 16px;
      }
      dd {
        font-size: $font3;
        margin-bottom: 16px;
        cursor: pointer;
        transition: color ease-in 0.3s;

        &:hover {
          color: $colorWhite1;
        }
      }
    }
    .contact {
      .btn {
        width: 205px;
        height: 57px;
        line-height: 57px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        color: $colorWhite1;
        text-align: center;
        background: $colorBgDark1;
        cursor: pointer;

        &:hover {
          background: #696969;
        }
      }
      .tip {
        display: flex;
        align-items: center;
        font-size: $font3;
        color: $colorWhite1;
        height: 32px;
        line-height: 32px;

        img {
          margin-right: 8px;
        }
      }
      .qrCode {
        display: block;
        margin: 20px auto 9px;
      }
    }
  }
  .bottom {
    height: 81px;
    background: $colorBgDark;
    font-size: $font1;
    color: $colorWhite2;
    box-shadow: inset 0px 1px 0px 0px rgba(217, 217, 217, 0.08);

    .bottom-main {
      @include width-1440;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 81px;
    }

    .copyright {
      display: flex;
      align-items: center;
    }
  }
}
</style>
