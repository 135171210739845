import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import Home from '@/pages/home/index.vue'
import Company from '@/pages/company/index.vue'
import Solution from '@/pages/solution/index.vue'
import Contact from '@/pages/contact/index.vue'
import ContactMobile from '@/pages/contact/mobile.vue'
import Privacy from '@/pages/privacy/index.vue'
import DSP from '@/pages/products/dsp.vue'
import SSP from '@/pages/products/ssp.vue'
// import GD from '@/pages/products/gd.vue'
import MD from '@/pages/products/md.vue'
import BD from '@/pages/products/bd.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/contactMobile',
    name: 'ContactMobile',
    meta: { title: '联系我们' },
    component: ContactMobile,
  },
  {
    path: '/contactQr',
    name: 'ContactQr',
    meta: { title: '联系我们' },
    component: ContactMobile,
  },
  {
    path: '/contactAuto',
    name: 'ContactAuto',
    meta: { title: '联系我们' },
    component: ContactMobile,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'Home',
        meta: { title: '首页' },
        component: Home,
      },
      {
        path: 'company',
        name: 'Company',
        meta: { title: '公司' },
        component: Company,
      },
      {
        path: 'solution',
        name: 'Solution',
        meta: { title: '解决方案' },
        component: Solution,
      },
      {
        path: 'contact',
        name: 'Contact',
        meta: { title: '联系我们' },
        component: Contact,
      },
    ],
  },
  {
    path: '/product',
    meta: { title: '产品' },
    component: Layout,
    redirect: '/product/ssp',
    children: [
      {
        path: 'ssp',
        name: 'SSP',
        meta: { title: '跨屏流量平台' },
        component: SSP,
      },
      // {
      //   path: 'gd',
      //   name: 'GD',
      //   meta: { title: '品牌营销投放平台' },
      //   component: GD,
      // },
      {
        path: 'dsp',
        name: 'DSP',
        meta: { title: '效果投放平台' },
        component: DSP,
      },
      {
        path: 'dsp2',
        name: 'DSP2',
        meta: { title: '效果投放平台' },
        component: DSP,
      },
      {
        path: 'md',
        name: 'MD',
        meta: { title: '即刻聚合平台' },
        component: MD,
      },
      {
        path: 'bd',
        name: 'BD',
        meta: { title: '大数据平台' },
        component: BD,
      },
    ],
  },
  // {
  //   path: '/privacy',
  //   component: Layout,
  //   redirect: '/privacy/index',
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'Privacy',
  //       meta: { title: '隐私政策' },
  //       component: Privacy,
  //     },
  //   ],
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
